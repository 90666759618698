import React, { useEffect, useState } from 'react';
import { PageProps } from 'gatsby';
import { WithTranslation, Trans, withTranslation } from 'react-i18next';

import Layout, { LayoutOptions, HeroSectionColors } from '../../../../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../../../../localization/translations';
import ExternalLink from '../../../../../components/ExternalLink/ExternalLink';
import { useLocaleContext } from '../../../../../context/Locale';

import './style.scss';

type Props = PageProps & WithTranslation;

const BudgetPlanner: React.FC<Props> = (props: Props) => {
  const { t } = props;
  const { activeLocaleTag } = useLocaleContext();
  const [iFrameClassName, setIFrameClassName] = useState('');
  const [iFrameStep, setIFrameStep] = useState(0);

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('budgetPlanner.seo.title'),
    },
    // page: {
    //   title: t('budgetPlanner.heading'),
    // },
    heroSection: {
      options: {
        heroImage: {
          fileName: 'hero-image-13.jpg',
          alt: 'Hero Image',
        },
        accentColor: HeroSectionColors.SKY_BLUE,
      },
    },
  };

  const budgetPlannerLinks = {
    en: {
      video: 'https://www.youtube.com/watch?v=eidft2ovmQc&t',
      frame: 'https://itools-ioutils.fcac-acfc.gc.ca/BP-PB-Widget/budget-planner',
    },
    fr: {
      video: 'https://www.youtube.com/watch?v=UC6FkmYKiHY',
      frame: 'https://itools-ioutils.fcac-acfc.gc.ca/BP-PB-Widget/planificateur-budgetaire',
    },
  };

  const addClassName = () => {
    if (iFrameStep) {
      setIFrameClassName('next-step');
    } else {
      setIFrameStep(1);
    }
  };

  return (
    <Layout options={layoutOptions} className="BudgetPlanner">
      <div className="grid-container ">
        <div className="row row-wrap BudgetPlanner__generic-gap">
          <section className="column column-100 budget-planner">
            <iframe
              className={iFrameClassName}
              onLoad={addClassName}
              src={budgetPlannerLinks[activeLocaleTag].frame}
              title="FCAC - Budget Planner"
              scrolling="yes"
            />
            <p className="BudgetPlanner__body-generic-gap">
              <Trans t={t} i18nKey="budgetPlanner.body">
                <ExternalLink href={budgetPlannerLinks[activeLocaleTag].video} />
              </Trans>
            </p>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.CUSTOMER_CENTRE)(BudgetPlanner);
